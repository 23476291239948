import { Box, Button, Typography } from "@mui/material"
import { exportIncomeTaxClientReturnsexport, getClientReturn, getIncomeTaxProfile } from "api/services/automation/income-tax";
import Table, { ColumnType } from "components/Table";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import AccessDenied from "./Access/accessDenide";
import moment from "moment";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { snack } from "components/toast";
import { handleError } from "utils/handleError";
import { getCurrentFormattedDateTime } from "utils";
import { exportDeletedClientsGroupPageReport } from "api/services/reports";
import { useClientData } from "context/ClientData";
import sortHandler from "components/Table/SortHandler";
import _ from "lodash";
import { SortDirection } from "data/constants";


const ClientIncometaxReturns = (state) => {

  const params = useParams();
  const navigate = useNavigate()
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [sort, setSort] = useState<any>({});
  const [checkAccess, setCheckAccess] = useState(true)
  const { data:profiledata, isLoading:isprofileloading }: ResType = useQuery(
    ["get-income-tax-profile", params.incometaxid],
    getIncomeTaxProfile,
  );
  const clientName = profiledata?.data?.profileDetails?.name;
  const { data, isLoading }: ResType = useQuery(
    ["get-client-return", params.incometaxid, { limit: pageCount, offset: page * pageCount,sort }],
    getClientReturn,
    {
      onSuccess: (res) => {
        setCheckAccess(res?.data?.accessDenied)
        setTotalCount(res?.data?.count);
      },
    }
  );
  // const {data:clientData} = useClientData();

// console.log(clientData,"Clientdata")


  const getFilingType = (type: any) => {
    const filingTypes = {
      "O": "Original Return",
      "D": "Defective Return",
      "R": "Revised Return",
      "T": "Rectification Return",
      "U": "Updated Return"
    };
    const abc = filingTypes[type]
    return abc
  }

  const getformType = (type: any) => {
    const itrTypes = {
      "1": "ITR-1",
      "2": "ITR-2",
      "2A": "ITR-2A",
      "3": "ITR-3",
      "4": "ITR-4",
      "4S": "ITR-4S",
      "5": "ITR-5",
      "6": "ITR-6",
      "7": "ITR-7"
    };

    const abc = itrTypes[type]
    return abc
  }

  const verificationStatus = {
    Y: "Verified",
    N: "Not Verified", //red
    'empty': "--"
  };

  const generateAssessmentYear = (assessmentYear: any) => {
    if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
      return "NA";
    } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
      const year = parseInt(assessmentYear);
      const nextYear = (year + 1).toString().slice(-2);
      return `AY ${year}-${nextYear}`;
    } else {
      return assessmentYear;
    }
  };
  let defaultColumns: Array<ColumnType> = [

    {
      key: "assmentYear",
      title: "Assessment Year",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (v) => (
        <Typography
        >
          {generateAssessmentYear(v?.assmentYear)}
        </Typography>
      ),
    },
    {
      key: "filingTypeCd",
      title: "Filing Type",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (row: any) => {
        return (
          <Typography>{getFilingType(row?.filingTypeCd)}</Typography>
        )
      },

    },
   

    {
      key: "formtypeCd",
      title: "ITR",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (row: any) => {
        return (
          <Typography>{getformType(row?.formtypeCd)}</Typography>
        )
      },
    },
    {
      key: "ackNum",
      title: "Acknowledgement #",
    },
    
    {
      key: "id",
      title: "Filing Date",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (row: any) => {
        return (
          <Typography variant="body1">{moment(row.submitTmstmp).format("DD MMM YYYY")}</Typography>
        );
      },
    },
    {
      key: "verStatus",
      title: "Verification Status",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (row: any) => (
        <Typography
          sx={{
            backgroundColor: row?.verStatus === 'Y' ? "#79c97a" : (row?.verStatus === 'N' ? "#ff3535" : ""),
            display: "inline-block",
            padding: "1px 5px",
            border: row?.verStatus ? `1px solid ${row?.verStatus === 'Y' ? "#79c97a" : (row?.verStatus === 'N' ? "#ff3535" : "")}` : "",
            borderRadius: "4px",
            color: "#222222",
            fontSize: "12px",
          }}
        >
          {row?.verStatus ? verificationStatus[row?.verStatus] : '--'}
        </Typography>
      ),
    },

  ];
  const [columns, setColumns] = useState(_.cloneDeep(defaultColumns));
  const handleSort = (v: any) => {
    sortHandler({
      key: v.key,
      columns,
      sortState: sort,
      setSortState: setSort,
      setColumns: setColumns,
    });
  };
  const handleRowClick = (v: any) => {
    navigate(`/atom-pro/income-tax/incometax/${params.incometaxid}/return/${v?.id}`)
  }
  const { mutate:mutate1 } = useMutation(exportIncomeTaxClientReturnsexport, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      const formattedDateTime = getCurrentFormattedDateTime();
      link.href = file;
      link.download = `ATOM Pro - ${clientName} Income Tax Returns ${formattedDateTime}.xlsx`;
      link.click();
      snack.success("Income Tax (PAN) Returns exported successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });
  
  const handleExport = () => {
    mutate1({
     incometaxid: params.incometaxid,
      ...state,
     

    });

  }
  return (
    <>
      <Box m={1}>
    
      {!checkAccess && (
        <AccessDenied />
      )}
     <Box display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
      <Button variant="outlined" color="secondary" onClick={handleExport}
        startIcon={<FileDownloadOutlinedIcon />}>
        Export
      </Button>
    </Box>
      {checkAccess && (
        <Box>
          {data && data?.data?.count > 0 ?
            <>
              <Table
              sx={{ pb: 0, mb: 0, height: '470px' }}
                // selection={{ selected, setSelected }}
                pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
                data={data?.data?.result || []}
                columns={columns}
                sortHandler={handleSort}
                loading={isLoading}
                onRowClick={handleRowClick}
              />
            </> : <>
              <Box textAlign="center" mt={20}>
                <Typography variant="subtitle1" color="rgba(0,0,0,0.5)">
                  No Data present
                </Typography>
              </Box>
            </>}

        </Box>
        
      )}
</Box>
    </>
  )
}

export default ClientIncometaxReturns