import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import useTitle from "hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import { ResType } from "types";
import { useQuery } from "react-query";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { StyledText1 } from "views/atom-pro/styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { formatToRupeess } from "utils/formattedAmount";
import Loader from "components/Loader";
import { getDemandData } from "api/services/automation/income-tax";
import moment from "moment";
import AccessDenied from "./Access/accessDenide";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Height } from "@mui/icons-material";

interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#fffff",
  padding: "3px",
  fontSize: "16px",
  fontWeight: "500",
  borderRadius: "5px",
  color: "#222222",
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function OutstandingDemandView() {
  useTitle("Demands");
  const params = useParams();
  const navigate = useNavigate();
  const [state, setInitialState] = useState<any>({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({});

  const { data, isLoading: demandIsLoading }: ResType = useQuery(
    ["income-demands", params.id],
    getDemandData,
    {
      onSuccess: (res) => {
        setInitialState(res?.data);
      },
    }
  );

  const generateAssessmentYear = (assessmentYear) => {
    if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
      return "NA";
    } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
      const year = parseInt(assessmentYear);
      const nextYear = (year + 1).toString().slice(-2);
      return `AY ${year}-${nextYear}`;
    } else {
      return assessmentYear;
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleItemClick = (fileName, type) => {
    const urlStart = `https://jss-vider-bucket.s3.ap-south-1.amazonaws.com/`;
    const amazon = type == "AMAZON" || type == null;
    const bharachCloud = type == "BHARATHCLOUD" || type == null;
    const url = amazon ? (urlStart + fileName) : bharachCloud ? (`${fileName['endPoint']}/${fileName['key']}`) : fileName?.key;
    window.open(url, '_blank');
  };

  const getFileName = (filePath, type) => {
    if (type == "AMAZON" || type == null) {
      const parts = filePath.split("/");
      return parts[parts.length - 1];
    } else if (type == 'MICROSOFT') {
      const parts = filePath['name'].split('/');
      return parts[parts.length - 1];
    } else if (type == 'BHARATHCLOUD') {
      const parts = filePath['key'].split('/');
      return parts[parts.length - 1];

    }
    return null

  };


  const TimelineComponent = ({ dateOfDemandRaised }) => {
    return (
      <Timeline position="left">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {state?.currentStatus ? "Current Status" : ""}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              sx={{
                backgroundColor: "#182F53",
                borderRadius: "50%",
                width: "16px",
                height: "16px",
                boxShadow: "inset 0 0 0 2px #fff",
              }}
            />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {state?.currentStatus ? state?.currentStatus : ""}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        {dateOfDemandRaised.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {Object.keys(item)[0] || ""}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                sx={{
                  backgroundColor: "#182F53",
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                  boxShadow: "inset 0 0 0 2px #fff",
                }}
              />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                {moment(item[Object.keys(item)[0]]).format("DD MMM YYYY") || ""}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };
  if (demandIsLoading) return <Loader />;
  console.log(state)
  return (
    <>
      {!state && <AccessDenied />}

      {state && (
        <>
          <Box height="80vh" overflow="auto">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon fontSize="small" />
              <Typography variant="subtitle2" ml={1}>
                Outstanding Demand List
              </Typography>
            </IconButton>
            <Grid container>
              <Grid item xs={8} sx={{ borderRight: "1px solid #d9d9d9" }}>
                <Typography
                  sx={{ marginBottom: "20px", color: "#2F343A", paddingLeft: "10px" }}
                  variant="subtitle1"
                >
                  Demand Info (DIN):{state.demandReferenceNo}
                </Typography>
                <Typography sx={{ paddingLeft: "10px" }} variant="subtitle2">
                  Rectification Right
                </Typography>

                <Grid item xs={12} p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          PAN
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.pan}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Assessment Year
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {generateAssessmentYear(state.assessmentYear)}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Current Status
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.currentStatus}
                        </StyledSpan>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Demand Raised Date
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.demandDateMilli
                            ? moment(state?.demandDateMilli * 1).format("DD MMM YYYY")
                            : "--"}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Section
                        </StyledText1>
                        <StyledSpan
                        //  bgColor={getRandomColor()}
                        >
                          {state?.sectionCodeText ? state?.sectionCodeText : "--"}
                        </StyledSpan>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Box>
                  <Box
                    padding={1}
                    sx={{ backgroundColor: "#F6F6F6" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="subtitle2">Demand Amount Breakup</Typography>
                    {state?.latestDemandResponse &&
                      Object.keys(state.latestDemandResponse).length > 0 && (
                        <Button color="info" variant="outlined" onClick={() => openModal()}>
                          View Response
                        </Button>
                      )}{" "}
                  </Box>

                  {/* <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography variant="body2" sx={{ color: "#2F2F2F", fontWeight: 600 }}>
                      Original Demand Amount
                    </Typography>
                    <Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                      >
                        <CurrencyRupeeIcon sx={{ width: "15px" }} />{" "}
                        {formatToRupeess(state?.originalOutstandingDemandAmount)}
                      </Box>
                    </Typography>
                  </Box> */}

                  {/* <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#A4A4A4" }} variant="body2">
                      <span style={{ color: "#2F2F2F", fontWeight: 600 }}>Rate of Interest </span>(
                      {state?.rateOfInterest} % for month or part of month)
                    </Typography>
                    <Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                      >
                        <CurrencyRupeeIcon sx={{ width: "15px" }} />{" "}
                        {formatToRupeess(state?.accruedInterestComputed)}
                      </Box>
                    </Typography>
                  </Box> */}
                  {/* <Divider sx={{ my: 1 }} /> */}

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Interest Start Date
                    </Typography>
                    <Typography>
                      {state?.interestStartDate ? moment(state?.interestStartDate).format("DD MMM YYYY") : "--"}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Accrued interest computation updated as on
                    </Typography>
                    <Typography>{moment().format("DD MMM YYYY")}</Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Time Period
                    </Typography>
                    <Typography>{state?.timePeriod} Months</Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Accrued Interest Computed
                    </Typography>
                    <Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                      >
                        <CurrencyRupeeIcon sx={{ width: "15px" }} />{" "}
                        {formatToRupeess(state?.accruedInterestComputed)}
                      </Box>
                    </Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Outstanding Demand Amount
                    </Typography>
                    <Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                      >
                        <CurrencyRupeeIcon sx={{ width: "15px" }} />{" "}
                        {formatToRupeess(state?.outstandingDemandAmount)}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={4} p={2}>
                <Typography variant="subtitle2" sx={{ color: "#667085" }}>
                  Timeline
                </Typography>
                {state && state.dateOfDemandRaised && (
                  <TimelineComponent dateOfDemandRaised={state?.dateOfDemandRaised} />
                )}
              </Grid>
            </Grid>
            <Modal
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  ...style,
                  zIndex: "100",
                  height: "60vh",
                  width: "60vw",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  overflow: "auto",
                }}
              >
                {state?.latestDemandResponse?.[0] && (
                  <>
                    <Typography sx={{ mt: 2 }}>
                      <strong>{state?.latestDemandResponse[0]?.arrearDemandRsnCd}</strong>
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      <strong>Transaction No:</strong>{" "}
                      {state.latestDemandResponse[0]?.transactionNo}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      <strong>Date of Response:</strong>{" "}
                      {moment(state.latestDemandResponse[0]?.confirmDt).format("DD MMM YYYY")}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      <strong>Last Updated:</strong>{" "}
                      {moment(state.latestDemandResponse[0]?.lastUpdatedAt).format("DD MMM YYYY")}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      <strong>Order Passed By:</strong>{" "}
                      {state.latestDemandResponse[0]?.orderPassedBy}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      <strong>Order Reference Number:</strong>{" "}
                      {state.latestDemandResponse[0]?.orderRefNum}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      <strong>Arrear Demand Reason Code:</strong>{" "}
                      {state.latestDemandResponse[0]?.arrearDemandRsnCd}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      <strong>Order Date:</strong>{" "}
                      {moment(state.latestDemandResponse[0]?.orderDt).format("DD MMM YYYY")}
                    </Typography>

                    {state.latestDemandResponse[0]?.attachments &&
                      state.latestDemandResponse[0]?.attachments.map(
                        (detail: any, index: number) => (
                          <Box display="flex" textAlign="center">
                            <Typography style={{ padding: "5px", margin: "5px" }}>
                              {index + 1}.
                            </Typography>
                            <div
                              key={index}
                              style={{
                                cursor: "pointer",
                                padding: "5px",
                                margin: "5px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                color: "blue",
                              }}
                              onClick={() => handleItemClick(detail, state.latestDemandResponse[0].storageSystem)}
                            >
                              {getFileName(detail, state.latestDemandResponse[0].storageSystem)}
                            </div>
                          </Box>
                        )
                      )}
                  </>
                )}
                <Button variant="outlined" color="secondary" sx={{ m: 1 }} onClick={closeModal}>
                  Close
                </Button>
              </Box>
            </Modal>
          </Box>
        </>
      )}
    </>
  );
}

export default OutstandingDemandView;
