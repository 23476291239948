import { Card, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";


const UpdatesTempNoticeFyi = ({ eProceedingFyi }) => {
    const navigate = useNavigate()
    const handleBoxClick = (id) => {
        // navigate(`/atom-pro/income-tax/proceeding-information/${id}`);
    };
    return (
        <>
            {eProceedingFyi && eProceedingFyi?.newItem && eProceedingFyi?.newItem?.length > 0 && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" color={"primary"}>New e-Proceeding (FYI) Excel: {eProceedingFyi?.newItem?.length}</Typography>
                    </Grid>

                    {eProceedingFyi?.newItem?.map(item => (
                        <Grid item xs={4} key={item.id}>
                            <Card >
                                <Grid item sx={{ marginBottom: 1 }} onClick={() => handleBoxClick(item.id)} bgcolor={"#ffffff"} m={0.5} padding={0.5}>
                                    <Typography variant="body2" ><span style={{ fontWeight: "600", color: "#00264d" }}>Type : </span> {item?.type}</Typography>
                                    <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#00264d" }}>Assessment Year : </span> {item?.assessmentYear === "0-01" ? "--" : item?.assessmentYear}</Typography>
                                    <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#00264d" }}>Proceeding Name : </span> {item?.proceedingName}</Typography>
                                    <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#00264d" }}>Notice Name : </span> {item?.noticeName}</Typography>
                                    <Typography variant="body2" ><span style={{ fontWeight: "600", color: "#00264d" }}>Response Due Date : </span> {item?.responseDueDate}</Typography>
                                </Grid>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    )
}

export default UpdatesTempNoticeFyi