import loadable from "@loadable/component";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { Permissions } from "data/permissons";
import PageWithPermission from "components/PageWithPermission";
import Subscription from "pages/Subscription";
import Ondemand from "pages/Ondemand";
import NotificationUsers from "pages/settings/preferences/notification-user-selection";
import NotificationsSingleUser from "pages/settings/preferences/notification-preferences-setup";
import AttendancePreferences from "pages/settings/attendance/attendancePreferences";
import EmployeeAttendanceReport from "pages/reports/employee-attendance-report";
import CommunicationDetails from "pages/settings/organization/communication-preference";
import ApplyDsc from "pages/register/apply-dsc";
import AddApplyDsc from "pages/register/add-applyDsc";
import PathWithPermission from "components/PathWithPermission";
import IncomeTaxReturns from "pages/atom-pro/income-tax-returns";
import IncomeTaxForms from "pages/atom-pro/income-tax-forms";
import IncomeTaxEProceedings from "pages/atom-pro/income-tax-eproceedings";
import IncomeTaxOrder from "pages/atom-pro/income-tax-order";
import FormFullView from "views/atom-pro/formFullView";
import ClientFullView from "views/atom-pro/clientFullView";
import ClientIncomeTaxView from "views/atom-pro/ClientIncomeTaxView";
import ClientForm from "views/atom-pro/clientForm";
import ReturnFullView from "views/atom-pro/ReturnFullView";
import ClientIncometaxReturns from "views/atom-pro/clientReturns";
import OutstandingDemandView from "views/atom-pro/OutstandingDemandView";
import ClientDemand from "views/atom-pro/clientDemand";
import ClientQuantumDocumets from "pages/client-view/quantum-check";
import EproceedingView from "views/atom-pro/EproceedingView";
import FYANoticeView from "views/atom-pro/FYANoticeView";
import FYINoticeView from "views/atom-pro/FYINoticeView";
import EproceedingFyiView from "views/atom-pro/EproceedingFyiView";
import ClientProceeding from "views/atom-pro/clientProceeding";
import OtpForgot from "views/login/OtpForgot";
import ClientIncomeTaxActivityLog from "views/atom-pro/clientActivityLog";
import ClientAutoStatus from "views/atom-pro/clientAutoStatus";
import IncomeTaxReports from "pages/atom-pro/reports";
import NewUpdates from "pages/atom-pro/new-updates";
import UpdatesFullVIew from "views/atom-pro/NewUpdates/ItemFullView"
import ApprovalBulkActivity from "pages/settings/bulk-activity/approval-bulk-activity";
import RecurringProfileBulkActivity from "pages/settings/bulk-activity/recurring-profile-bulk-activity";
import AttendanceBulkActivity from "pages/settings/bulk-activity/attendance-bulk-activity";
import DeletedClientGroups from "pages/settings/deleted-client-groups";
import OneDriveAuth from "pages/onedrive-auth";
import TaskPreferences from "pages/settings/preferences/task-preferences";
import PreferencesLayout from "pages/settings/preferences/layout"
import LogHourPreferences from "pages/settings/preferences/logHourPreferences"
import ClientsGroup from "pages/clients-group";
import ViewProfile from "pages/client-view/edit-permissions";
import EmailTemplateEdit from "pages/communication/templates/email/email-template-edit";
import BroadcastMessageDetails from "pages/communication/broadcast/broadcast-message-details";
import EmployeeAttendanceandTimesheetReport from "pages/reports/employee-attendance-and-timesheet-report";
import AttachmentAI from "pages/atom-pro/AI-page";



const CreateTask = loadable(() => import("views/tasks/board/CreateTask"));
const Calendar = loadable(() => import("pages/calendar"));
const Billing = loadable(() => import("pages/billing"));
const BillingClients = loadable(() => import("pages/billing/clients"));
const Billingclientview = loadable(() => import("pages/billing/clients/billingclientview"));
const Billingclientoverview = loadable(() => import("pages/billing/clients/billingclientoverview"));
const Invoices = loadable(() => import("pages/billing/invoices"));
const Proforma = loadable(() => import("pages/billing/proforma"));
const AddInvoice = loadable(() => import("pages/billing/invoices/add-invoice"));
const AddInvoiceReceipt = loadable(() => import("pages/billing/invoices/create-receipt"));
const EditInvoiceReceipt = loadable(() => import("pages/billing/invoices/edit-receipt"));
const EditInvoice = loadable(() => import("pages/billing/invoices/edit-invoice"));
const InvoicePreview = loadable(() => import("pages/billing/invoices/invoice-preview"));
//Proforma Invoice
const AddProforma = loadable(() => import("pages/billing/proforma/add-proforma"));
const EditProforma = loadable(() => import("pages/billing/proforma/edit-proforma"));
const ProformaPreview = loadable(() => import("pages/billing/proforma/proforma-preview"));
const ConvertInvoice = loadable(() => import("pages/billing/proforma/convert-proforma"));

const Receipts = loadable(() => import("pages/billing/receipts"));
const ReceiptPreview = loadable(() => import("pages/billing/receipts/receipt-preview"));
const AddReceipt = loadable(() => import("pages/billing/receipts/add-receipt"));
const EditReceipt = loadable(() => import("pages/billing/receipts/edit-receipt"));
const Expenditure = loadable(() => import("pages/billing/Expenditure"))
const Gst = loadable(() => import("pages/billing/Gst"));
const Tds = loadable(() => import("pages/billing/Tds"));
const DeletedClients = loadable(() => import("pages/settings/deleted-clients"));
const DeletedTerminatedTasks = loadable(() => import("pages/settings/deleted-terminated-tasks"));
const CompletedTasks = loadable(() => import("pages/settings/completed-tasks"));
const UpcomingTasks = loadable(() => import("pages/settings/upcoming-tasks"));
const DeletedUsers = loadable(() => import("pages/settings/deleted-users"));
const DeletedIncomeTaxClients = loadable(() => import("pages/settings/deleted-incometax"))
const DeletedGstrClients = loadable(() => import("pages/settings/deleted-gstr"))
const Login = loadable(() => import("pages/login"));
const CollectData = loadable(() => import("pages/collect-data"));
const ResetPassword = loadable(() => import("pages/reset-password"));
const Join = loadable(() => import("pages/join"));
const SignUp = loadable(() => import("pages/signup"));
const Layout = loadable(() => import("layout/primarylayout"));
const SettingsLayout = loadable(() => import("layout/settingslayout"));
const TaskBoard = loadable(() => import("pages/tasks"));
const Categories = loadable(() => import("pages/settings/categories"));
const BillingEntities = loadable(() => import("pages/settings/organization/billing-entities"));
const BillingEntityProfile = loadable(
  () => import("pages/settings/organization/billing-entity-profile")
);
const OrgChart = loadable(() => import("pages/settings/org-chats/orgChart"));

const MyProfile = loadable(() => import("pages/settings/profile"));
const Labels = loadable(() => import("pages/settings/labels"));
const Users = loadable(() => import("pages/settings/manage-users/users"));
const InviteUsers = loadable(() => import("pages/settings/manage-users/invited-users"));
const UserAvailabilty = loadable(() => import("pages/settings/manage-users/user-availability"));
const ViewUser = loadable(() => import("pages/settings/manage-users/users/view-user"));
const Teams = loadable(() => import("pages/settings/manage-users/teams"));
const ViewTeam = loadable(() => import("pages/settings/manage-users/teams/view-team"));
const NotificationsPreferences = loadable(() => import("pages/settings/notifications-preferences"));
const ClientNotificationPreferences = loadable(() => import("pages/settings/client-preferences"));
const ClientLayout = loadable(() => import("pages/settings/client-preferences/layout"));
const ClientPrefix = loadable(() => import("pages/settings/client-preferences/client-prefix"));

const Clients = loadable(() => import("pages/clients"));
const Leads = loadable(() => import("pages/leads"));
const DscRegister = loadable(() => import("pages/register/dsc-register"));
const ClientDscRegister = loadable(() => import("pages/client-view/dsc-register"));
const ClientDscRegisterView = loadable(() => import("pages/client-view/view-dsc-register"));
const GstUi = loadable(() => import("pages/register/gst-returns"));
const GstRegular = loadable(() => import("views/register/GstReturns/Regular"));
const GstCollector = loadable(() => import("views/register/GstReturns/Collector"));
const GstDeductor = loadable(() => import("views/register/GstReturns/Deductor"));
const GstrClients = loadable(() => import("pages/atom-pro/Gst/clients"));
const AdditionalOrdersAndNotices = loadable(() => import("pages/atom-pro/Gst/additionalOrdersAndNotices"));
const NoticesAndOrders = loadable(() => import("pages/atom-pro/Gst/NoticeAndOrders"));
const GstrReports = loadable(() => import("pages/atom-pro/Gst/reports"));
const AdditionalRecordsView = loadable(() => import("views/atom-pro/Gstr/Fullview/additionalRecordView"));
const NoticeAndOrderView = loadable(() => import("views/atom-pro/Gstr/Fullview/noticeAndOrderView"));
const GstrView = loadable(() => import("views/atom-pro/Gstr/modules/gstrView"));
const GstrProfile = loadable(() => import("views/atom-pro/Gstr/modules/profile"));
const AdditionalNotice = loadable(() => import("views/atom-pro/Gstr/modules/gstrAdditionalNotice"));
const NoticeAndOrders = loadable(() => import("views/atom-pro/Gstr/modules/noticeAndOrders"));
const GstrCompliance = loadable(() => import("views/atom-pro/Gstr/modules/gstrCompliance"));
const NoticeAndOrderFullView = loadable(() => import("views/atom-pro/Gstr/Fullview/noticeAndOrderFullView"));
const AdditonalNoticeFullView = loadable(() => import("views/atom-pro/Gstr/Fullview/additonalNoticeFullView"));
const ClientGstrActivityLog = loadable(() => import("views/atom-pro/Gstr/gstr-client/clientActivityLog"))
const ClientSyncStatus = loadable(() => import("views/atom-pro/Gstr/gstr-client/clientSyncStatus"))
const GstrCalendar = loadable(() => import("pages/atom-pro/Gst/Calendar"))
const NewGstrUpdates = loadable(() => import("pages/atom-pro/Gst/new-updates"))
const UpdatesGstrFullView = loadable(() => import("views/atom-pro/Gstr/NewUpdates/ItemFullView"))
const ClientGstrActivity = loadable(() => import("views/atom-pro/Gstr/gstr-client/clientActivity"))

const GstrDashboard = loadable(() => import("pages/atom-pro/Gst/gstr-dashboard"));

const UdinRegister = loadable(() => import("pages/register/udin-register"));

const ClientView = loadable(() => import("pages/client-view"));
const TasksView = loadable(() => import("pages/task-view"));
const Attachments = loadable(() => import("pages/client-view/attachments"));
const KybInfo = loadable(() => import("pages/client-view/kyb-info"));
const Credentials = loadable(() => import("pages/client-view/credentials"));
const Archives = loadable(() => import("pages/client-view/archives"));
const Tasks = loadable(() => import("pages/client-view/clientTasks"));
const ClientActivityLog = loadable(() => import("pages/client-view/client-activitylog"));
const ProfileDetails = loadable(() => import("pages/client-view/profile"));
const GroupProfileDetails = loadable(() => import("pages/client-view/group-profile"));
const GstReturns = loadable(() => import("pages/client-view/compliance"));
const UpComingTasks = loadable(() => import("pages/client-view/upcoming-tasks"));
const ClientQuantumDocuments = loadable(() => import("pages/client-view/client-qtm-documents"));
const ClientIncomeTaxActivity = loadable(() => import("views/atom-pro/clientActivity"))

const OrganizationProfile = loadable(
  () => import("pages/settings/organization/organization-profile")
);

const RecurringProfile = loadable(() => {
  return import("pages/client-view/recurring-profile");
});

// Dashboard
const Dashboard = loadable(() => import("pages/dashboard"));
const TasksByService = loadable(() => import("pages/dashboard/tasks-by-service"));
const ClientByCategory = loadable(() => import("pages/dashboard/client-by-category"));
const ClientCategory = loadable(() => import("pages/dashboard/client-categories"));
const ServiceCategory = loadable(() => import("pages/dashboard/service-categories"));
const OverDueTasks = loadable(() => import("pages/dashboard/over-due-tasks"));
const EmployeeTasksByStatus = loadable(() => import("pages/dashboard/employee-tasks-by-status"));
const RecurringProfilesTasks = loadable(() => import("pages/dashboard/recurringProfileTasks"))
const AllStatusTasks = loadable(() => import("pages/dashboard/AllStatusTasks"))

//ROLES AND PERMISSIONS
const Roles = loadable(() => {
  return import("pages/settings/manage-users/roles-permissions");
});
const EditPermissions = loadable(() => {
  return import("pages/settings/manage-users/roles-permissions/edit-permissions");
});

// STORAGE MANAGEMENT
const StorageManagement = loadable(() => {
  return import("pages/settings/storage-management");
});
const OnedriveAuth = loadable(() => {
  return import("pages/onedrive-auth");
});

const OnedriveConnection = loadable(() => import("views/signup/OneDrive"));
const BharathCloudConnection = loadable(() => import("views/signup/BharthCloud"));


const OnedriveAuthSignup = loadable(() => {
  return import("pages/Onedrive");
});

const GoogledriveAuth = loadable(() => {
  return import("pages/googledrive-auth");
});
const Storage = loadable(() => import("pages/storage"));
const MyStorage = loadable(() => import("views/storage/MyStorage"));
const AllClientsStorage = loadable(() => import("views/storage/AllClientsStorage"));
const CloudStorage = loadable(() => import("views/storage/Cloud"));
const GoogleDrive = loadable(() => import("views/storage/GoogleDrive"));
const OneDrive = loadable(() => import("views/storage/OneDrive"));
const BharathCloud = loadable(() => import("views/storage/BharathCloud"));

// FORMS
const Forms = loadable(() => import("pages/forms"));
const Esign = loadable(() => import("pages/forms/Esign"));
const FormTemplates = loadable(() => import("pages/forms/FormTemplates"));
const FormValidations = loadable(() => import("pages/forms/FormValidations"));
const FormBuilder = loadable(() => import("pages/forms/FormBuilder"));
const AccessForm = loadable(() => import("pages/forms/AccessForm"));

// IPRO
const ViewIproForm = loadable(() => import("pages/task-view/view-ipro"));
const ViewIproFormEntry = loadable(() => import("pages/task-view/view-ipro-entry"));
const IProAuditLog = loadable(() => import("pages/task-view/ipro-audit-log"));

// KYB DETAILS
const ViewKyb = loadable(() => import("pages/client-view/view-kyb"));

// SERVICES
const Services = loadable(() => import("pages/services"));
const AddService = loadable(() => import("pages/services/add-service"));
const ServiceView = loadable(() => import("pages/service-view"));
const ServiceOverView = loadable(() => import("pages/service-view/ServiceOverView"));
const ServiceInsights = loadable(() => import("pages/service-view/ServiceInsights"));
const ServiceDashboard = loadable(() => import("pages/service-view/Dashboard"));


// REPORTS
const InvoiceReports = loadable(() => import("pages/reports/invoice-reports"));
const Reports = loadable(() => import("pages/reports"));
const LogHoursReport = loadable(() => import("pages/reports/log-hours-report"));
const EmployeeLogHoursReport = loadable(() => import("pages/reports/employee-log-hours-report"));
const EmployeeTimeSheetReport = loadable(() => import("pages/reports/EmployeeTimeSheetReport"))
const LogHourFeeReport = loadable(() => import("pages/reports/log-hours-fee"));
const ClientsReport = loadable(() => import("pages/reports/clients-report"));
const TasksReport = loadable(() => import("pages/reports/tasks-report"));
const SubTaskReport = loadable(() => import("pages/reports/sub-task-report"));
const CustomReports = loadable(() => import("pages/reports/custom-reports"));
const TimesheetReports = loadable(() => import("pages/reports/timesheet-billable"))
const PredefinedReports = loadable(() => import("pages/reports/predefined-reports"));
const StatusWiseTasks = loadable(() => import("pages/reports/status-wise-tasks"));
const ServiceCategoryStatusWiseTasks = loadable(
  () => import("pages/reports/service-category-status-wise-tasks")
);
const ServiceandSubCategoryWiseTasks = loadable(
  () => import("pages/reports/service-subcategory-wise-tasks")
);
const UserBasedMasterReport = loadable(() => import("pages/reports/user-based-master-report"));
const OverDueTasksReport = loadable(() => import("pages/reports/over-due-tasks-report"));
const DetailedOverDueTasksReport = loadable(
  () => import("pages/reports/detailed-over-due-tasks-report")
);
const DetailedOverDueCompletedTasksReport = loadable(
  () => import("pages/reports/detailed-over-due-completed-tasks")
);
const EfficiencyReport = loadable(() => import("pages/reports/efficiency-report"));
const HighestNoOfTaskCompletion = loadable(
  () => import("pages/reports/highest-task-completion-reports")
);
// Invoice REPORTS
const TasksCompletedToBilledTasksReport = loadable(
  () => import("pages/reports/invoice/TasksCompletedToBilledTasksReport")
);
const TasksCompletedToUnBilledTasksReport = loadable(
  () => import("pages/reports/invoice/TasksCompletedToUnBilledTasksReport")
);
const ClientWiseTasksCompletedToBilledReport = loadable(
  () => import("pages/reports/invoice/ClientWiseTasksCompletedToBilledReport")
);
const ClientWiseTasksCompletedToUnBilledReport = loadable(
  () => import("pages/reports/invoice/ClientWiseTasksCompletedToUnBilledReport")
);
const InvoiceOverDueReport = loadable(() => import("pages/reports/invoice/InvoiceOverDueReport"));
const BalanceDueForInvoicesRaisedReport = loadable(
  () => import("pages/reports/invoice/BalanceDueForInvoicesRaisedReport")
);
const ReceiptManagementReport = loadable(
  () => import("pages/reports/invoice/ReceiptManagementReport")
);
const ProfomaInvoiceReport = loadable(
  () => import("pages/reports/invoice/ProfomaInvoiceReport")
);

//BRODCAST
const Communication = loadable(() => import("pages/communication"));
const TeamDiscussion = loadable(() => import("pages/communication/team-discussion"));
const UserGroups = loadable(() => import("pages/communication/userTeams"));
const ClientGroups = loadable(() => import("pages/communication/clientGroups"));
const EmailPreview = loadable(() => import("pages/communication/templates/email/email-template-preview"));
const ClientGroupView = loadable(
  () => import("pages/communication/clientGroups/client-group-view")
);
const ClientGroupTeamView = loadable(
  () => import("pages/communication/clientGroups/client-group-teamView")
);
const EmailTemplates = loadable(() => import("pages/communication/templates/email"));
const EmailTemplatesView = loadable(
  () => import("pages/communication/templates/email/add-email-template")
);
const EmailTemplatesEdit = loadable(
  () => import("pages/communication/templates/email/add-email-template")
);
const BroadcastMessage = loadable(() => import("pages/communication/broadcast/broadcast-message"));

const PushNotifications = loadable(
  () => import("pages/communication/templates/push-notifications")
);

//approval-levels

const LevelsHierarchy = loadable(() => import("pages/settings/manage-users/levels-hirerarchy"));
const AddLevel = loadable(() => import("pages/settings/manage-users/levels-hirerarchy/add-level"));
const ViewLevel = loadable(
  () => import("pages/settings/manage-users/levels-hirerarchy/view-levels")
);

const ApprovalProcess = loadable(() => import("pages/settings/approval-process"));
const ApprovalProcedue = loadable(
  () => import("pages/settings/approval-process/approval-procedure")
);
const ApprovalProcedueView = loadable(
  () => import("pages/settings/approval-process/approval-procedure-view")
);

//Automation income tax
const AtomPro = loadable(() => import("pages/atom-pro"));
const GstPro = loadable(() => import("pages/atom-pro/Gst"));
const GStProDashboard = loadable(() => import("pages/atom-pro/Gst/dashboard"));
const Websites = loadable(() => import("pages/atom-pro/website"));
const IncomeTaxDashboard = loadable(() => import("pages/atom-pro/income-tax-dashboard"));
const OutstandingDemand = loadable(() => import("pages/atom-pro/outstanding-demand"));
const ClientsIncomeTax = loadable(() => import("pages/atom-pro/clients"));
const FyaNotice = loadable(() => import("views/atom-pro/eProceedingNotice/fyaNotice"))
const EproceedingNotice = loadable(() => import("views/atom-pro/eProceedingNotice/eproceedingNotice"))
const Invalidate = loadable(() => import("views/atom-pro/invalidate"))
const AtomProCalendar = loadable(() => import("views/atom-pro/Dashboard/Calendar"))
const IncomeTaxLimitPage = loadable(() => import("views/atom-pro/Access/IncomeTaxLimit"));
const ClientChallan = loadable(() => import("views/atom-pro/clientChallan"));
const IncomeTaxChallans = loadable(() => import("pages/atom-pro/income-tax-challans"));
const EChallanFullView = loadable(() => import("views/atom-pro/eChallanFullView"));
const IncomeTaxMycas = loadable(() => import("pages/atom-pro/income-tax-mycas"));
const ClientMycas = loadable(() => import("views/atom-pro/clientMycas"));
const GstInvalidate = loadable(() => import('views/atom-pro/Gstr/invalidate'));


//BULK EDIT MODULE
const BulkEdit = loadable(() => import("pages/settings/bulk-activity"));
const TaskBulkEdit = loadable(() => import("pages/settings/bulk-activity/task-bulk-edit"))

//Wallets
// const WalletInfo = loadable(() => {
//   return import("layout/walletlayout")
// })


//Mails
const Mails = loadable(() => import('pages/mails'));
const Inbox = loadable(() => import('pages/mails/Inbox'));
const Sentbox = loadable(() => import('pages/mails/Sent'));
const MailFullView = loadable(() => import('pages/mails/GmailFullView'));
const ComposeMail = loadable(() => import('pages/mails/ComposeMail'));

//Approvals

const Approvals = loadable(() => import("pages/settings/approvals"));
const Attendance = loadable(() => import("pages/settings/approvals/attendence"));
const ApprovalPreferences = loadable(() => import("pages/settings/preferences/ApprovalPreferences"))
//ATOM PRO TAN
const Tan = loadable(() => import("pages/atom-pro/Tan"));
const TanDashboard = loadable(() => import("pages/atom-pro/Tan/dashboard"));
const TanClients = loadable(() => import("pages/atom-pro/Tan/clients"));
const TanClientFullView = loadable(() => import("views/atom-pro/Tan/clientInsideView/clientFullView"));
const TanClientIncomeTaxView = loadable(() => import("views/atom-pro/Tan/clientInsideView/ClientIncomeTaxView"));
const TanClientChallan = loadable(() => import("views/atom-pro/Tan/clientView/clientChallan"));
const TanEChallanFullView = loadable(() => import("views/atom-pro/Tan/clientInsideView/eChallanFullView"));
const TanFormFullView = loadable(() => import("views/atom-pro/Tan/clientInsideView/formFullView"));
const TanClientForm = loadable(() => import("views/atom-pro/Tan/clientView/clientForm"));
const TanIncomeTaxChallans = loadable(() => import("pages/atom-pro/Tan/income-tax-challans"));
const TanIncomeTaxForms = loadable(() => import("pages/atom-pro/Tan/income-tax-forms"));
const TanClientIncomeTaxActivityLog = loadable(() => import("views/atom-pro/Tan/clientInsideView/clientActivityLog"));
const TanIncomeTaxReports = loadable(() => import("pages/atom-pro/Tan/reports"));
const TanClientAutoStatus = loadable(() => import("views/atom-pro/Tan/clientInsideView/clientAutoStatus"));
const TanIncomeTaxMycas = loadable(() => import("pages/atom-pro//Tan/income-tax-mycas"));
const TanClientMycas = loadable(() => import("views/atom-pro/Tan/clientView/clientMycas"));
const TanNewUpdates = loadable(() => import("pages/atom-pro/Tan/new-updates"));
const TanUpdatesFullVIew = loadable(() => import("views/atom-pro/Tan/NewUpdates/ItemFullView"));
const DeletedIncomeTaxTanClients = loadable(() => import("pages/settings/deleted-incometaxtan"));
const TanInvalidate = loadable(() => import("views/atom-pro/Tan/invalidate"));
const TanTdsFormAnalytics = loadable(() => import("views/atom-pro/Tan/tdsFormAnalytics"));

//Income Tax Temp Notices
const IncomeTaxTempNotices = loadable(() => import("pages/atom-pro/income-tax-temp-notices"));
const ClientTempNotice = loadable(()=>import("views/atom-pro/clientTempNotice"));
const TempNoticesFilterPage = loadable(()=> import("views/atom-pro/eProceedingNotice/tempNotices"));

function RoutesContainer() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="attachment-ai" element={<AttachmentAI />} />
          <Route path="dashboard">
            <Route index element={<Dashboard />} />
            <Route path="tasks-by-service" element={<TasksByService />} />
            <Route path="client-by-category" element={<ClientByCategory />} />
            <Route path="client-categories" element={<ClientCategory />} />
            <Route path="service-categories" element={<ServiceCategory />} />


            <Route path="over-due-tasks" element={<OverDueTasks />} />
            <Route path="RecurringProfilesTasks" element={<RecurringProfilesTasks />} />
            <Route path="allTasks" element={<AllStatusTasks />} />
            <Route path="employee-tasks-by-status" element={<EmployeeTasksByStatus />} />
          </Route>

          <Route path="ondemand">
            <Route index element={<Ondemand />} />
          </Route>

          {/* <Route
            path="categories"
            element={
              <PageWithPermission name={Permissions.VIEW_CATEGORIES}>
                <Categories />
              </PageWithPermission>
            }
          /> */}

          <Route path="calendar" element={<Calendar />} />
          <Route path="create-task">
            <Route index element={<CreateTask />} />
          </Route>
          {/* <PageWithPermission name={Permissions.VIEW_REPORTS}>
              <GstrClients />
              </PageWithPermission> */}

          {/* automation  part*/}
          <Route path="atom-pro">
            <Route index element={<PageWithPermission name={Permissions.VIEW_ATOM_PRO}>
              <Websites />
            </PageWithPermission>} />
            <Route path="income-tax" element={<PageWithPermission
              name={Permissions.VIEW_INCOME_TAX}
            >
              <AtomPro />
            </PageWithPermission>}
            >
              <Route index element={<IncomeTaxDashboard />} />
              <Route path="demands" element={<OutstandingDemand />} />
              {/* <Route path="clients" element={<ClientsIncomeTax />} /> */}
              <Route path="returns" element={<IncomeTaxReturns />} />
              <Route path="dashboard" element={<IncomeTaxDashboard />} />
              <Route path="clients" element={<ClientsIncomeTax />} />
              <Route path="calendar" element={<AtomProCalendar />} />
              <Route path="return/:id" element={<ReturnFullView />} />
              <Route path="demand/:id" element={<OutstandingDemandView />} />
              <Route path="proceeding-action/:id" element={<EproceedingView />} />
              <Route path="proceeding-information/:id" element={<EproceedingFyiView />} />
              <Route path="proceeding-action/notice-fya/:itemId" element={<FYANoticeView />} />
              <Route path="proceeding-information/notice-fyi/:itemId" element={<FYINoticeView />} />
              <Route path="forms" element={<IncomeTaxForms />} />
              <Route path="my-cas" element={<IncomeTaxMycas />} />
              <Route path="challans" element={<IncomeTaxChallans />} />
              <Route path="proceedings" element={<IncomeTaxEProceedings />} />
              <Route path="temp-notices" element={<IncomeTaxTempNotices />} />
              <Route path="order" element={<IncomeTaxOrder />} />
              <Route path="forms/:id" element={<FormFullView />} />
              <Route path="e-challan/:id" element={<EChallanFullView />} />
              <Route path="client/:id" element={<ClientFullView />} />
              <Route path="reports" element={<IncomeTaxReports />} />
              <Route path="new-updates" element={<NewUpdates />} />
              <Route path="new-updates/:id" element={<UpdatesFullVIew />} />
              <Route path="fya-notice" element={<FyaNotice />} />
              <Route path="eproceeding-notice" element={<EproceedingNotice />} />
              <Route path="temp-notice" element={<TempNoticesFilterPage />} />
              <Route path="invalidate" element={<Invalidate />} />
              <Route path="incometax/:incometaxid" element={<ClientIncomeTaxView />} >
                <Route index element={<ClientFullView />} />
                <Route path="form" element={<ClientForm />} />
                <Route path="demand" element={<ClientDemand />} />
                <Route path="proceeding" element={<ClientProceeding />} />
                <Route path="temp-notice" element={<ClientTempNotice />} />
                <Route path="form/:id" element={<FormFullView />} />
                <Route path="my-cas" element={<ClientMycas />} />
                <Route path="e-challan/:id" element={<EChallanFullView />} />
                <Route path="challan" element={<ClientChallan />} />
                <Route path="return" element={<ClientIncometaxReturns />} />
                <Route path="return/:id" element={<ReturnFullView />} />
                <Route path="demand" element={<OutstandingDemand />} />
                {/* <Route path="activity" element={<ClientIncomeTaxActivityLog />} /> */}
                <Route path="activity" element={<ClientIncomeTaxActivity />} />
                <Route path="client-status" element={<ClientAutoStatus />} />
                {/* <Route path="proceeding" element={<IncomeTaxEProceedings />} /> */}
              </Route>
            </Route>

            <Route path="gst" element={<PageWithPermission name={Permissions.VIEW_GSTR}>
              <GstPro />
            </PageWithPermission>}>
              <Route path="dashboard" element={<GstrDashboard />} />
              <Route path="regular" element={<GstRegular />} />
              <Route path="collector" element={<GstCollector />} />
              <Route path="deductor" element={<GstDeductor />} />
              <Route path="clients" element={<GstrClients />} />
              <Route path="add-notice-orders" element={<AdditionalOrdersAndNotices />} />
              <Route path="notice-orders" element={<NoticesAndOrders />} />
              <Route path="reports" element={<GstrReports />} />
              <Route path="add-notice-order/:id" element={<AdditionalRecordsView />} />
              <Route path="notice-order/:id" element={<NoticeAndOrderView />} />
              <Route path="calendar" element={<GstrCalendar />} />
              <Route path="new-updates" element={<NewGstrUpdates />} />
              <Route path="new-updates/:id" element={<UpdatesGstrFullView />} />
              <Route path="invalidate" element={<GstInvalidate />} />
              <Route path="gstr/:gstrid" element={<GstrView />} >
                <Route index element={<GstrProfile />} />
                <Route path="compliance" element={<GstrCompliance />} />
                <Route path="additional-notice" element={<AdditionalNotice />} />
                <Route path="notice-orders" element={<NoticeAndOrders />} />
                <Route path="notice-orders/:id" element={<NoticeAndOrderFullView />} />
                <Route path="additional-notice/:id" element={<AdditonalNoticeFullView />} />
                {/* <Route path="activity-log" element={<ClientGstrActivityLog />} /> */}
                <Route path="activity-log" element={<ClientGstrActivity />} />
                <Route path="sync-status" element={<ClientSyncStatus />} />
              </Route>
            </Route>
            <Route path="incomeTaxLimit" element={<IncomeTaxLimitPage />} />
            <Route path="income-tax-tan" element={<Tan />}>
              <Route path="dashboard" element={<TanDashboard />} />
              <Route path="clients" element={<TanClients />} />
              <Route path="e-challans" element={<TanIncomeTaxChallans />} />
              <Route path="e-challan/:id" element={<TanEChallanFullView />} />
              <Route path="forms" element={<TanIncomeTaxForms />} />
              <Route path="form/:id" element={<TanFormFullView />} />
              <Route path="my-cas" element={<TanIncomeTaxMycas />} />
              <Route path="reports" element={<TanIncomeTaxReports />} />
              <Route path="new-updates" element={<TanNewUpdates />} />
              <Route path="new-updates/:id" element={<TanUpdatesFullVIew />} />
              <Route path="invalidate" element={<TanInvalidate />} />
              <Route path="tds-forms" element={<TanTdsFormAnalytics />} />
              <Route path="incometax/:incometaxid" element={<TanClientIncomeTaxView />}>
                <Route index element={<TanClientFullView />} />
                <Route path="challan" element={<TanClientChallan />} />
                <Route path="e-challan/:id" element={<TanEChallanFullView />} />
                <Route path="form" element={<TanClientForm />} />
                <Route path="form/:id" element={<TanFormFullView />} />
                <Route path="my-cas" element={<TanClientMycas />} />
                <Route path="activity" element={<TanClientIncomeTaxActivityLog />} />
                <Route path="client-status" element={<TanClientAutoStatus />} />
              </Route>
            </Route>
          </Route>

          <Route
            path="orgChart"
            element={
              <OrgChart />
            }
          />


          <Route path="attendance" element={<Attendance />} />
          <Route path="clients/" element={<Billingclientoverview />} />
          <Route path="clients-group/" element={<Billingclientoverview />} />
          <Route path="clients-group/:clientGroupId" element={<Billingclientview />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="receipts" element={<Receipts />} />
          <Route path="expenditure" element={<Expenditure />} />
          <Route path="gst" element={<Gst />} />
          <Route path="tds" element={<Tds />} />
          <Route path="proforma" element={<Proforma />} />
          <Route path="clients" element={<BillingClients />} />
          {/* </Route> */}

          <Route path="mails" element={<Mails />}>
            {/* <Route index element={<Mails />} /> */}
            <Route path="inbox" element={<Inbox />} />
            <Route path="sent" element={<Sentbox />} />
            <Route path=":gmailId" element={<MailFullView />} />
            <Route path='compose' element={<ComposeMail />} />
          </Route>

          <Route
            path="reports"
            element={
              <PageWithPermission name={Permissions.VIEW_REPORTS}>
                <Reports />
              </PageWithPermission>
            }
          >
            {/* <Route
              index
              element={
                <PageWithPermission name={Permissions.VIEW_REPORTS}>
                  <Reports />
                </PageWithPermission>
              }
            /> */}
            <Route index element={<PredefinedReports />} />
            <Route path="status-wise-tasks" element={<StatusWiseTasks />} />
            <Route
              path="service-category-status-wise-tasks"
              element={<ServiceCategoryStatusWiseTasks />}
            />
            <Route
              path="service-subcategory-wise-tasks"
              element={<ServiceandSubCategoryWiseTasks />}
            />
            <Route path="user-based-master-report" element={<UserBasedMasterReport />} />
            <Route path="over-due-tasks-report" element={<OverDueTasksReport />} />
            <Route path="detailed-over-due-tasks-report" element={<DetailedOverDueTasksReport />} />
            <Route
              path="detailed-over-due-completed-tasks"
              element={<DetailedOverDueCompletedTasksReport />}
            />
            <Route path="efficiency-report" element={<EfficiencyReport />} />
            <Route path="highest-task-completion-reports" element={<HighestNoOfTaskCompletion />} />
            <Route
              path="TasksCompletedToBilledTasksReport"
              element={<TasksCompletedToBilledTasksReport />}
            />
            <Route
              path="TasksCompletedToUnBilledTasksReport"
              element={<TasksCompletedToUnBilledTasksReport />}
            />
            <Route
              path="ClientWiseTasksCompletedToBilledReport"
              element={<ClientWiseTasksCompletedToBilledReport />}
            />
            <Route
              path="ClientWiseTasksCompletedToUnBilledReport"
              element={<ClientWiseTasksCompletedToUnBilledReport />}
            />
            <Route path="InvoiceOverDueReport" element={<InvoiceOverDueReport />} />
            <Route
              path="BalanceDueForInvoicesRaisedReport"
              element={<BalanceDueForInvoicesRaisedReport />}
            />
            <Route path="ReceiptManagementReport" element={<ReceiptManagementReport />} />
            <Route path="ProfomaInvoiceReport" element={<ProfomaInvoiceReport />} />
            <Route path="invoice-reports" element={<InvoiceReports />} />
            <Route path="custom-reports" element={<CustomReports />} />
            <Route path="timesheet" element={<TimesheetReports />} />
            <Route path="log-hours-report" element={<LogHoursReport />} />
            <Route path="log-hours-fee" element={<LogHourFeeReport />} />
            <Route path="employee-log-hours-report" element={<EmployeeLogHoursReport />} />
            <Route path="employee-attendance-report" element={<EmployeeAttendanceReport />} />
            <Route path="employee-attendance-and-timesheet-report" element={<EmployeeAttendanceandTimesheetReport />} />
            <Route path="clients-report" element={<ClientsReport />} />
            <Route path="tasks-report" element={<TasksReport />} />
            <Route path="sub-task-report" element={<SubTaskReport />} />
            <Route path="employee-TimeSheet-report" element={<EmployeeTimeSheetReport />} />
          </Route>
          <Route path="billing" element={<Billing />}>
            <Route path="clients/:clientId" element={<Billingclientview />} />
            <Route path="clients/" element={<Billingclientoverview />} />
            <Route path="clients-group/" element={<Billingclientoverview />} />
            <Route path="clients-group/:clientGroupId" element={<Billingclientview />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="receipts" element={<Receipts />} />
            <Route path="expenditure" element={<Expenditure />} />
            <Route path="gst" element={<Gst />} />
            <Route path="tds" element={<Tds />} />
            <Route path="proforma" element={<Proforma />} />
            <Route path="clients" element={<BillingClients />} />
          </Route>
          <Route path="communication" element={<Communication />}>
            <Route path="user-teams" element={<UserGroups />} />
            {/* <Route path="user-teams/:userGroupId" element={<UserView />} /> */}
            <Route path="team-discussion" element={<TeamDiscussion />} />
            <Route path="client-groups" element=


              {
                <PageWithPermission name={Permissions.BROADCAST_CLIENT_GROUP_VIEW}>

                  <ClientGroups />
                </PageWithPermission>
              }
            />
            <Route path="client-groups/:id" element={<ClientGroupView />} />
            <Route path="client-groups/:userGroupId/:id" element={<ClientGroupTeamView />} />
            <Route path="email" element={<PageWithPermission name={Permissions.BROADCAST_EMAIL_TEMPLATES_VIEW}>
              <EmailTemplates />
            </PageWithPermission>
            } />
            <Route path="email/:emailTemplateView" element={<EmailTemplatesView />} />
            {/* <Route path="email-templates" element={<AllEmailTemplates />} /> */}
            <Route path="email/:emailTemplateView" element={<EmailTemplatesView />} />
            <Route path="broadcast-message" element={<PageWithPermission name={Permissions.BROADCAST_BROADCAST_MESSAGE_VIEW}><BroadcastMessage /></PageWithPermission>} />
            <Route path="broadcast-message/:broadcastActivityId" element={

              <BroadcastMessageDetails />

            } />

            <Route path="email/:emailTemplateId/EmailTemplatePreview" element={<EmailPreview />} />
            <Route path="email/:emailTemplateId/EmailTemplateEdit" element={<EmailTemplateEdit />} />

            <Route path="push-notifications" element={<PushNotifications />} />
          </Route>
          <Route path="billing/invoices/add" element={<AddInvoice />} />
          <Route path="billing/receipts/add" element={<AddReceipt />} />
          <Route path="billing/proforma/add" element={<AddProforma />} />
          {/* <Route path="billing/invoices/:invoiceId/receipt" element={<AddInvoiceReceipt />} />  */}
          <Route path="billing/receipts/:receiptId/edit" element={<EditReceipt />} />
          <Route path="billing/invoices/:invoiceId/edit" element={<EditInvoice />} />
          <Route path="billing/proforma/:proformaId/edit" element={<EditProforma />} />
          <Route path="/billing/proforma/:proformaId/convert" element={<ConvertInvoice />} />

          <Route path="storage" element={<Storage />}>
            <Route
              path="my-storage"
              element={
                <PageWithPermission name={Permissions.VIEW_STORAGE}>
                  <MyStorage />
                </PageWithPermission>
              }
            />
            <Route
              path="cloud-storage"
              element={
                <PageWithPermission name={Permissions.VIEW_STORAGE}>
                  <CloudStorage />
                </PageWithPermission>
              }
            >
              <Route path="google-drive" element={<GoogleDrive />} />
              <Route path="one-drive" element={<OneDrive />} />
              <Route path="bharath-cloud" element={<BharathCloud />} />

            </Route>
            <Route
              path="all-clients-storage"
              element={
                <PageWithPermission name={Permissions.VIEW_CLIENT_STORAGE}>
                  <AllClientsStorage />
                </PageWithPermission>
              }
            />
          </Route>
          <Route path="forms" element={<Forms />}>
            <Route index element={<FormTemplates />} />
            <Route path="form-validations" element={<FormValidations />} />
          </Route>
          <Route path="task-board">
            <Route
              index
              element={
                <PageWithPermission
                  name={[Permissions.VIEW_ALL_TASKS, Permissions.VIEW_ASSIGNED_TASKS]}
                >
                  <TaskBoard />
                </PageWithPermission>
              }
            />
            <Route
              path=":taskId"
              element={
                <PageWithPermission name={[Permissions.VIEW_ASSIGNED_TASKS]}>
                  <TasksView />
                </PageWithPermission>
              }
            />
          </Route>

          <Route
            path="leads"
            element={
              <PageWithPermission name={Permissions.VIEW_LEADS}>
                <Leads />
              </PageWithPermission>
            }
          />
          <Route path="services">
            <Route index element={<Services />} />
            {/* <Route path="add" element={<AddService />} /> */}
            <Route
              path="add"
              element={
                <PathWithPermission name={Permissions.CREATE_SERVICES} path={"/services/add"}>
                  <AddService />
                </PathWithPermission>
              }
            />
            <Route path=":serviceId" element={<ServiceView />}>
              <Route path="overview" element={
                <ServiceOverView />
              } />
              <Route path="insights" element={
                <ServiceInsights />
              } />
              <Route path="dashboard"
                element={
                  <PageWithPermission name={Permissions.VIEW_SERVICE_DASHBOARD} >
                    <PageWithPermission name={Permissions.VIEW_ALL_TASKS} >
                      <ServiceDashboard />
                    </PageWithPermission>
                  </PageWithPermission>
                }

              />

            </Route>
          </Route>

          <Route path="clients">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.VIEW_CLIENTS}>
                  <Clients />
                </PageWithPermission>
              }
            />
            <Route path=":clientId" element={<ClientView />}>
              <Route
                path="kyb-info"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_KYB}>
                    <KybInfo />
                  </PageWithPermission>
                }
              />
              <Route
                path="credentials"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_PASSWORDS}>
                    <Credentials />
                  </PageWithPermission>
                }
              />
              <Route
                path="attachments"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_ATTACHMENTS}>
                    <Attachments />
                  </PageWithPermission>
                }
              />
              <Route
                path="recurring-profile"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_RECURRING_PROFILE}>
                    <RecurringProfile />
                  </PageWithPermission>
                }
              />
              <Route
                path="profile"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_PROFILE}>
                    <ProfileDetails />
                  </PageWithPermission>
                }
              />
              <Route
                path="group-profile"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_GROUP_PROFILE}>
                    <GroupProfileDetails />
                  </PageWithPermission>
                }
              />
              <Route path="compliance" element={<GstReturns />} />
              <Route path="archives" element={<Archives />} />
              <Route path="upcoming-tasks" element={<UpComingTasks />} />
              <Route path="client-tasks" element={<Tasks />} />
              <Route path="roles-permissions" element={<ViewProfile />} />
              <Route path="client-activitylog" element={<ClientActivityLog />} />
              {/* <Route path="client-quantum-documents" element={<ClientQuantumDocuments />} /> */}
              <Route path="client-quantum-documents" element={<ClientQuantumDocumets />} />

              <Route path="dsc-register">
                <Route index element={<PageWithPermission name={Permissions.VIEW_DSC_REGISTER}><ClientDscRegister /></PageWithPermission>} />
                <Route path=":dscId" element={<PageWithPermission name={Permissions.VIEW_DSC_REGISTER}><ClientDscRegisterView /></PageWithPermission>} />
              </Route>
            </Route>
          </Route>
          <Route path="clients-group">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.VIEW_CLIENT_GROUPS}>
                  <ClientsGroup />
                </PageWithPermission>
              }
            />
            <Route path=":clientId" element={<ClientView />}>
              <Route
                path="kyb-info"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_GROUP_KYB}>
                    <KybInfo />
                  </PageWithPermission>
                }
              />
              <Route
                path="credentials"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_GROUP_PASSWORDS}>
                    <Credentials />
                  </PageWithPermission>
                }
              />
              <Route
                path="attachments"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_GROUP_ATTACHMENTS}>
                    <Attachments />
                  </PageWithPermission>
                }
              />
              <Route
                path="recurring-profile"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_GROUP_RECURRING_PROFILE}>
                    <RecurringProfile />
                  </PageWithPermission>
                }
              />
              <Route
                path="profile"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_PROFILE}>
                    <ProfileDetails />
                  </PageWithPermission>
                }
              />
              <Route
                path="group-profile"
                element={
                  <PageWithPermission name={Permissions.VIEW_CLIENT_GROUP_PROFILE}>
                    <GroupProfileDetails />
                  </PageWithPermission>
                }
              />
              <Route path="compliance" element={<GstReturns />} />
              <Route path="archives" element={<Archives />} />
              <Route path="upcoming-tasks" element={<UpComingTasks />} />
              <Route path="client-tasks" element={<Tasks />} />
              {/* <Route path="roles-permissions" element={<ViewProfile />} /> */}
              <Route path="client-activitylog" element={<ClientActivityLog />} />
              {/* <Route path="client-quantum-documents" element={<ClientQuantumDocuments />} /> */}
              <Route path="client-quantum-documents" element={<ClientQuantumDocumets />} />

              <Route path="dsc-register">
                <Route index element={<PageWithPermission name={Permissions.VIEW_DSC_REGISTER}><ClientDscRegister /></PageWithPermission>} />
                <Route path=":dscId" element={<PageWithPermission name={Permissions.VIEW_DSC_REGISTER}><ClientDscRegisterView /></PageWithPermission>} />
              </Route>
            </Route>
          </Route>
          <Route path="dsc-register">
            <Route index element={<PageWithPermission name={Permissions.VIEW_DSC_REGISTER}><DscRegister /></PageWithPermission>} />
            <Route path="apply-dsc" element={<ApplyDsc />} />
            <Route path="apply-dsc/add" element={<AddApplyDsc />} />

            {/* <Route path="dsc-register" element={<GstUi />}>
            <Route index element={<DscRegister />} />
            <Route path="gst">
              <Route path="gstr-1" element={<GstHome />} />
              <Route path="gstr-3b" element={<GstHome />} />
              <Route path="gstr-9" element={<GstHome />} />
              <Route path="gstr-9c" element={<GstHome />} />
              <Route path="gstr-08" element={<GstHome />} />
            </Route> */}
            <Route path=":dscId" element={<PageWithPermission name={Permissions.VIEW_DSC_REGISTER}><ClientDscRegisterView /></PageWithPermission>} />
          </Route>
          <Route path="udin-register">
            <Route index element={<PageWithPermission name={Permissions.VIEW_UDIN}><UdinRegister /></PageWithPermission>} />
          </Route>

          <Route path="return-tracker" element={<GstUi />}>
            <Route path="regular" element={<GstRegular />} />
            <Route path="collector" element={<GstCollector />} />
            <Route path="deductor" element={<GstDeductor />} />
          </Route>

          {/* <Route path="return-tracker" element={<GstUi />} /> */}
        </Route>

        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="communication-preference" element={<CommunicationDetails />}></Route>
          {/* <Route path="task-preference" element={<TaskPreferences />}></Route> */}


          <Route
            path="task-preference"
            element={
              <PageWithPermission name={Permissions.MANAGE_GENERAL_PREFERENCES}>
                <PreferencesLayout />
              </PageWithPermission>
            }
          >
            <Route index element={<TaskPreferences />} />
            <Route path="loghour" element={<LogHourPreferences />} />
            <Route path="approvals" element={<ApprovalPreferences />} />

            {/* <Route index />
            <Route path="preferences"  >
            </Route> */}

          </Route>

          <Route
            path="attendance-preference"
            element={
              <PageWithPermission name={Permissions.MANAGE_ATTENDANCE_PREFERENCES}>
                <AttendancePreferences />
              </PageWithPermission>
            }
          />

          <Route
            path="categories"
            element={
              // <PageWithPermission name={Permissions.VIEW_CATEGORIES}>
              <Categories />
              // </PageWithPermission>
            }
          />
          {/* <Route path="billing-entities"> */}
          {/* <Route path="settings/billing-entities/create" element={<CreateBillingEntity />} /> */}
          {/* <Route index element={<BillingEntities />} /> */}
          {/* <Route path=":billingEntityId" element={<BillingEntityProfile />} /> */}

          {/* </Route> */}
          <Route path="billing-entities">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.MANAGE_ORG_BILLING_ENTITIES}>
                  <BillingEntities />
                </PageWithPermission>
              }
            />
            <Route
              path=":billingEntityId"
              element={
                <PageWithPermission name={Permissions.MANAGE_ORG_BILLING_ENTITIES}>
                  <BillingEntityProfile />
                </PageWithPermission>
              }
            />
          </Route>
          <Route path="profile" element={<MyProfile />} />
          <Route path="users">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.VIEW_USERS}>
                  <Users />
                </PageWithPermission>
              }
            />
            <Route
              path=":userId"
              element={
                <PageWithPermission name={Permissions.VIEW_USERS}>
                  <ViewUser />
                </PageWithPermission>
              }
            />
          </Route>
          <Route path="invited-users" element={<InviteUsers />} />
          <Route path="user-availability" element={<UserAvailabilty />} />
          <Route path="storage-management" element={<StorageManagement />} />
          <Route path="completed-tasks" element={<CompletedTasks />} />
          <Route path="deleted-tasks" element={<DeletedTerminatedTasks />} />
          <Route path="deleted-clients" element={<DeletedClients />} />
          <Route path="deleted-client-groups" element={<DeletedClientGroups />} />
          <Route path="deleted-users" element={<DeletedUsers />} />
          <Route path="deleted-incomtex" element={<DeletedIncomeTaxClients />} />
          <Route path="deleted-incomtaxtan" element={<DeletedIncomeTaxTanClients />} />
          <Route path="deleted-gstr" element={<DeletedGstrClients />} />
          <Route path="upcoming-tasks" element={<UpcomingTasks />} />
          {/* <Route path='client-preferences' element={<ClientNotificationPreferences />} /> */}

          <Route path="client-preferences" element={<ClientLayout />} >
            <Route
              index
              element={
                <PageWithPermission name={Permissions.MANAGE_CLIENT_PREMISSION}>
                  <ClientNotificationPreferences />
                </PageWithPermission>
              }
            />
            <Route path="prefix" element={<ClientPrefix />} />

            {/* <Route index />
            <Route path="preferences"  >
            </Route> */}

          </Route>

          {/* <Route path="atom-pro">
            <Route index element={<Websites />} />
            <Route path="income-tax" element={<AtomPro />}>
              <Route index element={<IncomeTaxDashboard />} />
              <Route path="demands" element={<OutstandingDemand />} /> */}

          {/* <Route path="client-preferences">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.MANAGE_CLIENT_PREMISSION}>
                  <ClientNotificationPreferences />
                </PageWithPermission>
              }
            />
          </Route> */}
          <Route path="notifications-preferences" element={<NotificationsPreferences />} />
          <Route path="preferences">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.MANAGE_NOTIFICATION_PREFERENCES}>
                  <NotificationUsers />
                </PageWithPermission>
              }
            />
            <Route
              path=":id"
              element={
                <PageWithPermission name={Permissions.MANAGE_NOTIFICATION_PREFERENCES}>
                  <NotificationsSingleUser />
                </PageWithPermission>
              }
            />
          </Route>
          {/* <Route path="preferences" element={<NotificationUsers />} /> */}
          {/* <Route path="preferences/:id" element={<NotificationsSingleUser />} /> */}
          <Route path="teams">
            <Route index element={<Teams />} />
            <Route path=":teamId" element={<ViewTeam />} />
          </Route>
          <Route path="labels" element={<Labels />} />
          <Route path="roles-permissions">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.MANAGE_ORG_ROLES}>
                  <Roles />
                </PageWithPermission>
              }
            />
            <Route path=":roleId" element={<EditPermissions />} />
          </Route>
          <Route path="levels-hierarchy">
            <Route index element={<LevelsHierarchy />} />
            <Route path="add-level" element={<AddLevel />} />
            <Route path="view-levels" element={<ViewLevel />} />

          </Route>
          <Route path="approval-process">
            <Route index element={<ApprovalProcess />} />
            <Route path="approvel-procedure" element={<ApprovalProcedue />} />
            <Route path="approvel-procedure-view" element={<ApprovalProcedueView />} />
          </Route>
          <Route path="approvals" element={<Approvals />} />
          <Route
            path="organization-profile"
            element={
              <PageWithPermission name={Permissions.MANAGE_ORG_PROFILE}>
                <OrganizationProfile />
              </PageWithPermission>
            }
          />
          <Route
            path="org-chart"
            element={
              <PageWithPermission name={Permissions.VIEW_ORGANIZATION_CHART}>
                <OrgChart />
              </PageWithPermission>
            }
          />
          <Route
            path="tasks-bulk-activity"
            element={
              <PageWithPermission name={[Permissions.VIEW_ALL_TASKS, Permissions.VIEW_ASSIGNED_TASKS]}>
                <TaskBulkEdit />
              </PageWithPermission>} />
          <Route path="approvals-bulk-activity" element={<ApprovalBulkActivity />} />
          <Route path="recurring-bulk-activity" element={<RecurringProfileBulkActivity />} />
          <Route path="attendance-bulk-activity" element={<AttendanceBulkActivity />} />
        </Route>
        {/* <Route path="/wallet" element={<WalletInfo />}> */}
        {/* </Route> */}

        <Route path="/forms/builder/:formId" element={<FormBuilder />} />
        <Route path="/forms/access/:formId" element={<AccessForm />} />
        <Route path="/tasks/:taskId/iPro/:formId" element={<ViewIproForm />}>
          <Route path="view" element={<ViewIproFormEntry />} />
          <Route path="edit" element={<FormBuilder />} />
          <Route path="audit-log" element={<IProAuditLog />} />
        </Route>
        <Route path="/clients/:clientId/kyb-info/:formId" element={<ViewKyb />}>
          <Route path="view" element={<ViewIproFormEntry />} />
          <Route path="edit" element={<FormBuilder />} />
          <Route path="fill-details" element={<AccessForm withoutAppbar />} />
          <Route path="audit-log" element={<IProAuditLog />} />
        </Route>
        <Route path="/billing/invoices/:invoiceId/preview" element={<InvoicePreview />} />
        <Route path="/billing/proforma/:proformaId/preview" element={<ProformaPreview />} />
        <Route path="/billing/receipts/:receiptId/preview" element={<ReceiptPreview />} />

        <Route path="/forms/:formId/fields/:fieldId/esign" element={<Esign />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password-otp" element={<OtpForgot />} />

        <Route path="/collect-data/:collectId" element={<CollectData />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/join" element={<Join />} />
        <Route path="/onedrive-auth" element={<OnedriveAuth />} />
        <Route path="/onedrive-auth-signup" element={<OnedriveAuthSignup />} />
        <Route path="/googledrive-auth" element={<GoogledriveAuth />} />
        <Route path="/signup-onedrive" element={<OnedriveConnection />} />
        <Route path="/signup-bharathcloud" element={<BharathCloudConnection />} />
        <Route path="subscription">
          <Route index element={<Subscription />} />
        </Route>


      </Routes>
    </Router>
  );
}

export default RoutesContainer;
