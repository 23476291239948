import React, { useState } from "react";
import axios from "axios";
import useTitle from "hooks/useTitle";
import { Typography, Button, Box } from "@mui/material";
import Loader from "components/Loader";
import { useMutation } from "react-query";
import { uploadandStoreFile, uploadFile } from "api/services/storage";
import { snack } from "components/toast";

interface ApiResponse {
  assistantMessage: string;
  tokenCount: number;
}

const AttachmentAI = () => {
  useTitle("Vider AI");

  const [assistantMessage, setAssistantMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  console.log(assistantMessage, "assistantMessage");

  const parseResponse = (message: string) => {
    const lines = message.split("\n");
    return lines.map((line, index) => {
      const sanitizedLine = line.replace(/\*\*(.*?)\*\*/g, "$1").trim();

      if (line.startsWith("###")) {
        const heading = sanitizedLine.replace("###", "").trim();
        return (
          <div key={index} style={{ marginTop: "50px" }}>
            <h3 style={{ color: "#182F53", fontSize: "20px" }}>{heading}</h3>
          </div>
        );
      } else {
        // Remove asterisks surrounding words
        const sanitizedLine = line.replace(/\*\*(.*?)\*\*/g, "$1");
        return (
          <Typography
            variant="inherit"
            key={index}
            style={{ marginLeft: "20px", fontSize: "18px" }}
          >
            {sanitizedLine}
          </Typography>
        );
      }
    });
  };

  const { mutateAsync } = useMutation(uploadandStoreFile, {
    onSuccess: (res: any) => {
      // onChange(1, res?.data);
      console.log("res", res);
    },
    onError: (err: any) => {
      // snack.error(handleError(err));
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFile(file);
      setAssistantMessage("");
      setError("");
    }
  };

  const handleSubmit = async () => {
    if (!uploadedFile) {
      setError("Please upload a file before submitting.");
      return;
    }

    const formData: any = new FormData();
    formData.append("pdfs[]", uploadedFile);
    formData.append("submit_analyze", "true");
    setLoading(true);

    try {
      const apiResponse = await axios.post<ApiResponse>(
        "https://oparantap.in/vider/api.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setAssistantMessage(apiResponse.data.assistantMessage || "No message received");
      if (apiResponse.data.assistantMessage) {
        try {
          const formData = new FormData();
          formData.append("file", uploadedFile);
          formData.append("folderId", "");
          formData.append("type", "viderAi");
          formData.append("aiResult", apiResponse.data.assistantMessage);

          // http.post("/common/upload", formData).then((res: any) => {setUrl(res.data.Location);
          //     onChange(res.data.key);
          //   })

          await mutateAsync(formData);
        } catch (err: any) {
          snack.error(err.response.data.message);
        }
      }

      setError("");
    } catch (error) {
      setError("Error uploading PDF");
      setAssistantMessage("");
      console.error(error);
    }

    setLoading(false);
  };

  return (
    <div style={{ padding: "40px" }}>
      <Typography mb={4} variant="h5">
        Experience VIDER AI
      </Typography>
      <Box sx={{ display: "flex", gap: "30px" }}>
        <label
          htmlFor="file-upload"
          style={{
            width: "700px",
            height: "100px",
            border: "2px dashed #ccc",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            transition: "border-color 0.3s, background-color 0.3s",
          }}
        >
          {uploadedFile ? (
            <span style={{ color: "#182F53", fontSize: "16px", textAlign: "center" }}>
              {uploadedFile.name.length > 20
                ? `${uploadedFile.name.substring(0, 20)}...`
                : uploadedFile.name}
            </span>
          ) : (
            <span style={{ color: "#666", fontSize: "16px", textAlign: "center" }}>Upload PDF</span>
          )}
        </label>
        <input
          id="file-upload"
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />

        <div style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!uploadedFile}
          >
            Submit
          </Button>
        </div>
      </Box>
      {loading && <Loader />}

      {assistantMessage && (
        <div style={{ marginTop: "20px" }}>
          <div>{parseResponse(assistantMessage)}</div>
        </div>
      )}
      {error && (
        <div style={{ marginTop: "20px", color: "red" }}>
          <h3>{error}</h3>
        </div>
      )}
      <Box sx={{ borderTop: "20px" }}>
        <Typography variant="inherit" color="#000000" fontWeight={"900"} mt={4} fontSize={12}>
          <Typography color="rgb(95, 33, 32)" display="inline" fontSize={12}>
            Disclaimer:
          </Typography>
          The AI-powered document analysis feature in ATOM provides an initial analysis of uploaded
          PDFs. It is designed as an assistant to accelerate your work. Please always carefully
          review and verify the information extracted by the AI before taking any action. Vider
          assumes no responsibility for the accuracy or completeness of the information generated by
          the AI analysis tool.{" "}
        </Typography>
      </Box>
    </div>
  );
};

export default AttachmentAI;
