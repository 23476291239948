import { Visibility } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material"
import { exportclientDemand, getClientDemand, getIncomeTaxProfile } from "api/services/automation/income-tax";
import Table, { ColumnType } from "components/Table";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { formatToRupeess } from "utils/formattedAmount";
import { useEffect, useState } from "react";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import moment from "moment";
import AccessDenied from "./Access/accessDenide";
import Loader from "components/Loader";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getCurrentFormattedDateTime } from "utils";
import { link } from "fs";
import { snack } from "components/toast";
import { handleError } from "utils/handleError";
import _ from "lodash";
import sortHandler from "components/Table/SortHandler";
import { SortDirection } from "data/constants";


const ClientDemand = (state) => {
  const params = useParams();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const defaultColumns: Array<ColumnType> = [

    {
      key: "assessmentYear",
      title: "Assessment Year",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (v) => (
        <Typography
        >
          {generateAssessmentYear(v?.assessmentYear)}
        </Typography>
      )

    },
    {
      key: "sectionCodeText",
      title: "Section",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (v) => (
        <Typography

        >
          {v?.sectionCodeText}
        </Typography>
      ),
    },
    {
      key: "demandDateMilli",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      title: (
        <Box display="flex" alignItems="center">
          <DateRangeIcon style={{ width: "20px", marginRight: '5px' }} />Date
        </Box>
      ),
      render: (v) => (
        <Typography
        >
          {v?.demandDateMilli ? moment(v?.demandDateMilli*1).format('DD MMM YYYY') :"--"}
        </Typography>
      ),
    },
  
    {
      key: "outstandingDemandAmount",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      title: (
        <Box display="flex" alignItems="center">
          Amount(<CurrencyRupeeIcon style={{ width: "15px" }} />)
        </Box>
      ),
      render: (v) => (
        <Tooltip  title={
          <>
            Original Outstanding Demand Amount: {v?.originalOutstandingDemandAmount}
            <br />
            Accrued Interest: {v.accruedInterest}
          </>
        }>
          <Typography
          >
            <Box display='flex' alignItems='center'>
              <CurrencyRupeeIcon sx={{ width: '15px' }} /> {formatToRupeess(v?.outstandingDemandAmount)}
            </Box>
          </Typography>
        </Tooltip>
      )
    },
    {
      key: '',
      title: 'Actions',
      render: (rowData: any) => <Actions data={rowData} />,

    }

  ];
  const [sort, setSort] = useState<any>({});


  const [columns, setColumns] = useState(_.cloneDeep(defaultColumns));
  const handleSort = (v: any) => {
    sortHandler({
      key: v.key,
      columns,
      sortState: sort,
      setSortState: setSort,
      setColumns: setColumns,
    });
  };

  useEffect(() => {
    return () => {
      //   dispatch(resetFilters());
    };
  }, []);

  const [checkAccess, setCheckAccess] = useState(true)
  const { data, isLoading }: ResType = useQuery(
    ["client-demand", params.incometaxid, { limit: pageCount, offset: page * pageCount, sort }],
    getClientDemand,
    {
      onSuccess: (res) => {
        setCheckAccess(res?.data?.accessDenied)
        setTotalCount(res?.data?.count);
      },
    }
  );


  const { data: profiledata, isLoading: isprofileloading }: ResType = useQuery(
    ["get-income-tax-profile", params.incometaxid],
    getIncomeTaxProfile,
  );

  const clientName = profiledata?.data?.profileDetails?.name;
  const { mutate: mutate1 } = useMutation(exportclientDemand, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      const formattedDateTime = getCurrentFormattedDateTime();
      link.href = file;
      link.download = `ATOM Pro - ${clientName} Income Tax (PAN) Outstanding Demands ${formattedDateTime}.xlsx`;
      link.click();
      snack.success("Income Tax (PAN) outstanding demands exported successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });

  const handleExport = () => {
    mutate1({
      incometaxid: params.incometaxid,
      ...state,


    });

  }
  if (isLoading) return <Loader />
  return (
    <>
     <Box m={1}>
      {!checkAccess && (
        <AccessDenied />
      )}
      <Box display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
        <Button variant="outlined" color="secondary" onClick={handleExport}
          startIcon={<FileDownloadOutlinedIcon />}>
          Export
        </Button>
      </Box>
      {checkAccess && (
        <Box p={0.5}>
          <Table
           sx={{ pb: 0, mb: 0, height: '470px' }}
            pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
            data={data?.data?.result || []}
            columns={columns}
            sortHandler={handleSort}
            loading={isLoading}
          />
        </Box>
      )}
      </Box>
    </>
  );
};


const generateAssessmentYear = (assessmentYear) => {
  if (!assessmentYear || assessmentYear === '0' || assessmentYear === '') {
    return 'NA';
  } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
    const year = parseInt(assessmentYear);
    const nextYear = (year + 1).toString().slice(-2);
    return `AY ${year}-${nextYear}`;
  } else {
    return assessmentYear;
  }
}

function Actions(props: any) {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/atom-pro/income-tax/demand/${props.data?.id}`);
  };

  return (
    <IconButton onClick={handleViewMoreClick} size="small">
      <Visibility />
    </IconButton>
  )
}


export default ClientDemand