import { Add, Delete, Edit } from "@mui/icons-material";
import { Autocomplete, Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { deleteHoliday, getInvoicePreference, insertOrganizationpreference, updateWeekendData } from "api/services/invoice.preferencess";
import { snack } from "components/toast";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import UpdateWeekend from "./updateWeekend";
import UpdateOvertime from "./updateOvertime";
import moment from "moment";
import AddHoliday from "./addHoliday";
import Table from "components/Table";
import { useConfirm } from "context/ConfirmDialog";

function AttendancePreferences() {
    const queryClient = useQueryClient();
    var currentYearString = moment().format('YYYY');
    var currentYearInt = parseInt(currentYearString, 10);
    const yearsArray = Array.from({ length: currentYearInt - 2009 + 1 }, (_, i) => 2010 + i);
    const sortedYearsArray = yearsArray.sort((a, b) => b - a);
    const [state, setState]: any = useState([]);
    const [weekendData, setWeekendData] = useState([]);
    const [hours, setHours] = useState<any>(null);
    const [minutes, setMinutes] = useState<any>(null);
    const [weekendEdit, setWeekendEdit] = useState<boolean>(false);
    const [overtimeEdit, setOvertimeEdit] = useState<boolean>(false);
    const [AddOpenHoliday, setAddOpenHoliday] = useState<boolean>(false);
    const [years, setyears] = useState<any>(sortedYearsArray);
    const [year, setYear] = useState<any>(currentYearInt);
    const [holidays, setHolidays] = useState<any>([]);
    const [totalHolidays, setTotalHolidays] = useState<any>([]);
    const [isMarkAbsent, setIsMarkAbsent] = useState<any>("NO");
    const [initialIsMarkAbsent, setInitialIsMarkAbsent] = useState<any>(null);
    const confirm = useConfirm()

    const { data: invoicePreferences, isLoading: isInvoicePreferencesLoading } = useQuery(
        "attendance-preferences",
        getInvoicePreference,
        {
            onSuccess: (res: any) => {
                res?.data?.holidayPreferences?.addholiday.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
                setTotalHolidays(res?.data?.holidayPreferences?.addholiday);
                const Yearholidays = res?.data?.holidayPreferences?.addholiday.filter((item: any) => moment(item?.date).format("YYYY") === String(year))
                setHolidays(Yearholidays);
                setWeekendData(res?.data?.holidayPreferences?.updateweekend);
                setHours(res.data.holidayPreferences.updateovertime.hours);
                setMinutes(res.data.holidayPreferences.updateovertime.minutes);
                const initialValue = res?.data?.holidayPreferences?.isMarkAbsent || "NO";
                setIsMarkAbsent(initialValue);
                setInitialIsMarkAbsent(initialValue);
            },
        }
    );
    useEffect(() => {
        const Yearholidays = totalHolidays.filter((item: any) => moment(item?.date).format("YYYY") === String(year))
        setHolidays(Yearholidays);
    }, [year])

    const onChange = (e: any) => {
        setYear(e.target.value);
    }

    const { mutate, isLoading: insertLoad } = useMutation(updateWeekendData, {
        onSuccess: () => {
            snack.success("Preferences Updated");
            queryClient.invalidateQueries("attendance-preferences");
        },
        onError: (err: any) => {
            snack.error(err);
        }
    });

    const handleUpdateIsAbsent = () => {
        const apiData = { typeofdata: "isMarkAbsent", data: { isMarkAbsent } }
        confirm({
            msg: "Are you sure, Employees Attendance should be marked as Absent",
            action: () => mutate({ data: apiData }),
        });
    }

    return (
        <Box sx={{ p: 3, pb: 0 }}>
            <Grid container mt={2} >
                <Grid item xs={4} >
                    <Box sx={{ border: "1px solid #cfcbd6", padding: "10px", borderRadius: "10px", mr: 1, height: "120px" }} >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography sx={{ mt: 1, ml: 2 }} variant="h5">Weekend</Typography>
                            <IconButton onClick={(e) => setWeekendEdit(true)} sx={{ mr: 2 }}>
                                <Edit />
                            </IconButton>
                        </Box>
                        <Box flexWrap="wrap" display="flex" p={2} >
                            {weekendData?.map((item: any, index: any) => (
                                <Box
                                    px="10px"
                                    py="5px"
                                    borderRadius={2}
                                    key={index}
                                    border="1px solid rgb(24, 47, 83, 0.2)"
                                    sx={{ mr: 2 }}
                                >
                                    <Typography color="primary" variant="caption" sx={{ fontWeight: "bold" }}>
                                        {item?.label}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ border: "1px solid #cfcbd6", padding: "10px", borderRadius: "10px", ml: 1, height: "120px" }} >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h6" sx={{ mt: 0.5, ml: 2, fontSize: "20px" }} >Standard Working Hours in a Day</Typography>
                            <IconButton onClick={(e) => setOvertimeEdit(true)} sx={{ mr: 2 }}>
                                <Edit />
                            </IconButton>
                        </Box>
                        <Box textAlign={"center"} >
                            <Typography variant="body1" sx={{ fontSize: "20px" }}  >{hours ? hours['label'] : null} Hrs {minutes ? minutes["label"] : null} Mns</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ border: "1px solid #cfcbd6", padding: "10px", borderRadius: "10px", ml: 1, height: "120px" }} >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography variant="h6" sx={{ mt: 1, ml: 1, fontSize: "15px" }} >
                                        Employees who fail to record their Attendance should be marked as Absent?
                                    </Typography>
                                </Grid>

                                <Grid item xs={4} >
                                    <FormControl style={{ marginTop: "10px" }}>
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            fullWidth
                                            size="small"
                                            placeholder="Absent"
                                            labelId="Mark Absent"
                                            id="isMarkAbsent"
                                            value={isMarkAbsent || "NO"}
                                            onChange={(event) => {
                                                setIsMarkAbsent(event.target.value); // Update state with the selected value
                                            }}
                                            label="Is Mark Absent"
                                        >
                                            <MenuItem key={"YES"} value="YES">
                                                YES
                                            </MenuItem>
                                            <MenuItem key={"NO"} value="NO">
                                                NO
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {isMarkAbsent !== initialIsMarkAbsent && ( // Show the button only if value changes
                                        <Button
                                            style={{ marginTop: "5px" }}
                                            color="secondary"
                                            variant="contained"
                                            onClick={handleUpdateIsAbsent}
                                        >
                                            Update
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>


                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Box sx={{ mt: 2 }} >
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h5" >List of Holidays</Typography>
                            <Box>
                                <TextField
                                    name="category"
                                    value={year}
                                    onChange={onChange}
                                    size="small"
                                    label="Year"
                                    select
                                    sx={{ width: "250px" }}
                                >
                                    {years?.map((option: any, index: any) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Button
                                    sx={{ ml: 2, pt: 1 }}
                                    variant="outlined"
                                    startIcon={<Add />}
                                    color="secondary"
                                    onClick={() => setAddOpenHoliday(true)}
                                >Add</Button>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                            <Table
                                data={holidays || []}
                                loading={false}
                                sx={{ height: "360px", mb: 0, pb: 0 }}
                                columns={[
                                    {
                                        title: "Date",
                                        key: "date",
                                        render: (row) => {
                                            return moment(row?.date).format("DD-MM-YYYY");
                                        },
                                    },
                                    {
                                        title: "Day",
                                        key: "date",
                                        render: (row) => {
                                            return moment(row?.date).format("dddd");
                                        }
                                    },
                                    {
                                        title: "Description",
                                        key: "holiday",
                                    },
                                    {
                                        key: "actions",
                                        title: "Actions",
                                        render: (row: any) => <Actions data={row} setTotalHolidays={setTotalHolidays} setHolidays={setHolidays} year={year} />,
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <UpdateWeekend open={weekendEdit} setOpen={setWeekendEdit} />
            <UpdateOvertime open={overtimeEdit} setOpen={setOvertimeEdit} />
            <AddHoliday open={AddOpenHoliday} setOpen={setAddOpenHoliday} />
        </Box>
    )
}

const Actions = ({ data, setTotalHolidays, setHolidays, year }) => {
    const confirm = useConfirm();

    const { mutateAsync } = useMutation(deleteHoliday, {
        onSuccess: (res: any) => {
            setTotalHolidays(res?.data?.holidayPreferences?.addholiday);
            const Yearholidays = res?.data?.holidayPreferences?.addholiday.filter((item: any) => moment(item?.date).format("YYYY") === String(year))
            setHolidays(Yearholidays);
            snack.success("Holiday Deleted");
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });

    const handleDelete = () => {
        confirm({
            msg: "Are you sure you want to delete this Holiday?",
            action: () => mutateAsync(data),
        });
    };

    return (
        <Box>
            <Box display="flex" gap={1}>
                <IconButton size="small" onClick={handleDelete}>
                    <Delete />
                </IconButton>
            </Box>
        </Box>
    );
};

export default AttendancePreferences