import { Box, Button, Paper, Typography } from "@mui/material";
import { exportClientsReport, exportEmployeeAttendanceReport, exportEmployeeLogHoursReport } from "api/services/reports";
import Loader from "components/Loader";
import Table from "components/Table";
import { snack } from "components/toast";
import moment from "moment";
import { useMutation } from "react-query";
import { getCurrentFormattedDateTime, getTitle } from "utils";
import { handleError } from "utils/handleError";

function Report({ data, state, isLoading, isError }) {
    const { mutate, isLoading: isloading } = useMutation(exportEmployeeAttendanceReport, {
        onSuccess: (res: any) => {
            const arr = new Uint8Array(res.data?.data);
            const blob = new Blob([arr], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const file = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            const formattedDateTime = getCurrentFormattedDateTime();
            link.href = file;
            link.download = `ATOM - Employee Attendance Report ${formattedDateTime}.xlsx`;
            link.click();
            snack.success("Report Exported to Excel");
        },
        onError: (err: any) => {
            snack.error(handleError(err));
        },
    });

    const handleExport = () => {
        mutate({
            ...state,
            fromDate: state.fromDate ? moment(state.fromDate).format("YYYY-MM-DD") : null,
            toDate: state.toDate ? moment(state.toDate).format("YYYY-MM-DD") : null,
        });
    };

    if (isLoading) return <Loader />;

    if (isError) return null;

    if (!data) return null;

    return (
        <Paper sx={{ mt: 2, p: 2 }}>
            <Box display="flex" justifyContent="space-between" mb={2} alignItems="center">
                <Box>
                </Box>
                <Box>
                    <Button variant="outlined" color="secondary" disabled={isloading} onClick={handleExport}>
                        Export to Excel
                    </Button>
                </Box>
            </Box>
            <Table loading={false} data={data || []} columns={columns} />
        </Paper>
    );
}

const columns = [
    {
        title: "Employee ID",
        key: "employeeId",
    },
    {
        title: "User Name",
        key: "username",
    },
    {
        title: "Role",
        key: "role",
    },
    { key: "Present", title: "Present" },
    { key: "Absent", title: "Absent" },
    {
        title: "Leave",
        key: "Leave",
    },
    {
        title: "Approved Leaves",
        key: "ApprovedLeave",
    },
    {
        title: "Rejected Leave",
        key: "RejectedLeave",
    },
];

export default Report;