import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface FilterPayload {
  checked: boolean;
  value: {
    label: string;
    value: string | number | Array<any>;
  };
}

type Filter = {
  billingType: Array<{ label: string; value: number }>;
  approvalStatus: Array<{ label: string; value: number }>;
  logHourType: Array<{ label: string; value: number }>;
  user: Array<{ label: string; value: number }>;
  clients: Array<{ label: string; value: number }>;
  categories: {
    label: string;
    value: number;
    subCategories?: Array<{ label: string; value: number }>;
  } | null; // Add subCategories
  subCategories: Array<{ label: string; value: number }>;
};

const filterState: Filter = {
  billingType: [],
  approvalStatus: [],
  logHourType: [],
  user: [],
  clients: [],
  categories: null, // Initialize as null
  subCategories: [],
};

interface InitialState {
  selectedFilters: Filter;
  appliedFilters: Filter;
  search: string;
  selected: string;
}

const initialState: InitialState = {
  selectedFilters: filterState,
  appliedFilters: filterState,
  search: "",
  selected: "clients",
};
export const timesheetReportSlice = createSlice({
  name: "timesheetReport",
  initialState,
  reducers: {
    handleFilters(state: any, action: PayloadAction<FilterPayload>) {
      if (state.selected === "categories") {
        // Handle category selection
        state.selectedFilters.categories = action.payload.checked ? action.payload.value : null;
        // Reset subCategories when a category is deselected
        state.selectedFilters.subCategories = [];
      } else if (state.selected === "subCategories") {
        // Handle subcategory selection
        if (action.payload.checked) {
          state.selectedFilters.subCategories.push(action.payload.value);
        } else {
          state.selectedFilters.subCategories = state.selectedFilters.subCategories.filter(
            (item: any) => item.value !== action.payload.value.value
          );
        }
      } else {
        // Existing logic for other filters
        let filterItem = state.selectedFilters[state.selected];
        if (action.payload.value.label === "Select All") {
          state.selectedFilters[state.selected] = action.payload.checked
            ? action.payload.value.value
            : [];
        } else if (action.payload.checked) {
          state.selectedFilters[state.selected].push(action.payload.value);
        } else {
          state.selectedFilters[state.selected] = filterItem.filter(
            (item: any) => item.value !== action.payload.value.value
          );
        }
      }
    },

    resetFilters(state) {
      state.selectedFilters = filterState;
      state.appliedFilters = filterState;
    },

    handleSelected(state, action: PayloadAction<string>) {
      state.selected = action.payload;
    },

    handleSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },

    // handleApply(state: InitialState) {
    //   // state.appliedFilters = state.selectedFilters;
    //   // removes duplicate filter values
    //   Object.keys(state.selectedFilters).forEach((filter: any) => {
    //     if (state.selectedFilters[filter] && state.selectedFilters[filter].length > 0) {
    //       state.appliedFilters[filter] = state.selectedFilters[filter].filter(
    //         (item: any, index: number, self: any) =>
    //           self.findIndex((v: any) => v.value === item.value) === index
    //       );
    //     } else {
    //       state.appliedFilters[filter] = state.selectedFilters[filter];
    //     }
    //   });
    // },

    handleApply(state: InitialState) {
      Object.keys(state.selectedFilters).forEach((filter: any) => {
        if (filter === "categories") {
          // Handle single selection for categories
          state.appliedFilters[filter] = state.selectedFilters[filter];
        } else {
          // Handle multi-selection for other filters
          if (state.selectedFilters[filter] && state.selectedFilters[filter].length > 0) {
            state.appliedFilters[filter] = state.selectedFilters[filter].filter(
              (item: any, index: number, self: any) =>
                self.findIndex((v: any) => v.value === item.value) === index
            );
          } else {
            state.appliedFilters[filter] = state.selectedFilters[filter];
          }
        }
      });
    },

    handleRemove(state, action: PayloadAction<{ filter: string; filterItemIndex: number }>) {
      const { filter, filterItemIndex } = action.payload;
      state.appliedFilters[filter] = state.appliedFilters[filter].filter(
        (item: any, index: number) => index !== filterItemIndex
      );
      state.selectedFilters[filter] = state.appliedFilters[filter].filter(
        (item: any, index: number) => index !== filterItemIndex
      );
    },
  },
});

export const selectTimesheetReport = (state: RootState) => state.timesheetReport;

export const {
  handleFilters,
  resetFilters,
  handleSelected,
  handleSearch,
  handleApply,
  handleRemove,
} = timesheetReportSlice.actions;
export default timesheetReportSlice.reducer;
